import dynamic from 'next/dynamic';
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  closeCartMismatchDialog,
  closeForgotPasswordFormRedux,
  closeForgotPasswordMessageRedux,
  closeLoginModalRedux,
  closeMobileSearchDialog,
  closeRegisterModalRedux,
  closeRlcSelectorRedux,
  closeShowReviewCartOOS,
  closeSubscriptionLimitDialog,
  closeTrustpilotWidget,
  closeValidateEmailFormRedux,
  closeValidateEmailMessageRedux,
  openLoginModalRedux,
  openShowReviewCartOOS,
  openTrustpilotWidget,
  swapInLoginModalRedux,
  swapInRegisterModalRedux,
} from '../redux/modalSlice';

const RegisterFormG = dynamic(
  () => import('../components/CustomerRegisterForm/RegisterFormG'),
  {
    ssr: false,
  }
);

const LoginFormG = dynamic(
  () => import('../components/CustomerRegisterForm/LoginFormG'),
  {
    ssr: false,
  }
);

const MUIModal = dynamic(() => import('../components/MUIModal/MUIModal'), {
  ssr: false,
});

const ValidateEmailForm = dynamic(
  () =>
    import('../components/CustomerRegisterForm/ValidateEmailForm').then(
      (mod) => mod.ValidateEmailForm
    ) as any,
  {
    ssr: false,
  }
);
const ValidateEmailMessage = dynamic(
  () =>
    import('../components/CustomerRegisterForm/ValidateEmailForm').then(
      (mod) => mod.ValidateEmailMessage
    ) as any,
  {
    ssr: false,
  }
);
const ForgotPasswordForm = dynamic(
  () =>
    import('../components/CustomerRegisterForm/ForgotPasswordForm').then(
      (mod) => mod.ForgotPasswordForm
    ) as any,
  {
    ssr: false,
  }
);
const ForgotPasswordMessage = dynamic(
  () =>
    import('../components/CustomerRegisterForm/ForgotPasswordForm').then(
      (mod) => mod.ForgotPasswordMessage
    ) as any,
  {
    ssr: false,
  }
);

const DealStorePopup = dynamic(
  () => import('../components/DealstorePopup/DealstorePopup'),
  { ssr: false }
);

const RLCSelector = dynamic(
  () => import('../components/RLCSelector/RLCSelector'),
  {
    ssr: false,
  }
);

const ChangeLanguageSuggestion = dynamic(
  () =>
    import('../components/ChangeLanguageSuggestion/ChangeLanguageSuggestion'),
  { ssr: false }
);

const MobileSearchDialog = dynamic(
  () => import('../components/MobileSearchDialog/MobileSearchDialog'),
  { ssr: false }
);

import { useRouter } from 'next/router';
import useDidMountEffect from '../hooks/useDidMount';
import { fetchCarts } from '../redux/cartSlice';
import {
  fetchCountryList,
  fetchPlatformList,
  fetchRegionsList,
  setCurrency,
} from '../redux/globalSlice';
import {
  fetchUser,
  googleOneTapLogin,
  set2FARequired,
} from '../redux/userSlice';

import { PageLoaderContext } from '../contexts/PageLoaderContext';
import { SnackbarContext } from '../contexts/SnackbarContext';

const PageLoader = dynamic(
  () => import('../components/PageLoader/PageLoader'),
  {
    ssr: false,
  }
);

const CustomizedSnackbars = dynamic(
  () => import('../components/MUISnackbar/MUISnackbar'),
  {
    ssr: false,
  }
);

const ReviewYourCart = dynamic(
  () => import('../components/ReviewYourCart/ReviewYourCart'),
  { ssr: false }
);

const CartMismatchNotice = dynamic(
  () => import('../components/CartMismatchNotice/CartMismatchNotice'),
  { ssr: false }
);

const TrustpilotWidget = dynamic(
  () => import('../components/TrustpilotWidget/TrustpilotWidget'),
  { ssr: false }
);

const SubscriptionLimitSnackbar = dynamic(
  () =>
    import(
      '../components/Membership/components/SubscriptionLimitSnackbar/SubscriptionLimitSnackbar'
    ),
  { ssr: false }
);

import axios from 'axios';
import Script from 'next/script';
import { ErrorBoundary } from 'react-error-boundary';
import { useTheme } from 'styled-components';
import Cookies from 'universal-cookie';
import { CartItemInterface } from '../Interface/CartItemInterface';
import { LocalesType } from '../Interface/LocalesInterface';
import useToggleMembership from '../components/EnableMembershipCard/hook/useToggleMembership';

import ErrorFallbackComponent from '../components/ErrorFallbackComponent/ErrorFallbackComponent';
import NoJSMessage from '../components/NoJSMessage/NoJSMessage';
import { apiConstants } from '../constants/apiConstants';
import { useAnalytics } from '../contexts/AnalyticsProvider';
import { useESim } from '../contexts/ESimContext';
import { setNsureCookie } from '../utils/eventTracking';
import {
  getLocalStorageExp,
  saveLocalStorageExp,
} from '../utils/localStorageUtils';
import {
  getLocaleForCountry,
  getLocaleToBeUsed,
} from '../utils/localizationUtils';
import { sendRecombeeUserSetEvent } from '../utils/recombee';
import {
  WE_USER_EVENT_SOCIAL_LOGIN_INITIATE,
  WE_USER_EVENT_TRACK_USER,
} from '../utils/we';

const GlobalSiteBanner = dynamic(
  () => import('../components/GlobalSiteBanner/GlobalSiteBanner'),
  { ssr: false }
);

const WEBENGAGE_LICENCE_CODE = process.env.NEXT_PUBLIC_WEBENGAGE_LICENSE_CODE;
interface Props {
  children: ReactElement;
}

const cookies = new Cookies();

const FooterPaymentMethodsContext = createContext<{
  footerPaymentMethodsLoading: boolean;
  footerPaymentMethods: {
    icon: string;
    id: number;
    displayName: string;
    priority: number;
    methodName: string;
  }[];
}>({
  footerPaymentMethodsLoading: true,
  footerPaymentMethods: [],
});

export const useFooterPaymentMethods = () =>
  useContext(FooterPaymentMethodsContext);

const Layout = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { locale } = useRouter();
  const { isESim } = useESim();

  const { cleverTap, setCleverTap } = useAnalytics();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [dealStore, setDealStore] = useState<boolean>(false);
  const [langauageSelectionModal, setLanguageSelectionModal] =
    useState<boolean>(false);

  const { snackbarState, dispatchSnackbar } = useContext(SnackbarContext);
  const { pageLoaderState } = useContext(PageLoaderContext);

  const {
    userRegister,
    userLogin,
    validateEmailForm,
    validateEmailMessage,
    forgotPasswordForm,
    forgotPasswordMessage,
    rlcSelector,
    mobileSearchDialog,
    showReviewCartOOS,
    showCartMismatchDialog,
    showTrustpilotWidget,
    subscriptionLimitDialog,
  } = useAppSelector((state) => state.modal);
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const userData = useAppSelector((state) => state.user);
  const global = useAppSelector((state) => state.global);
  const cart = useAppSelector((state) => state.cart);

  const [notAvailableProducts, setNotAvailableProducts] = useState<
    CartItemInterface[] | null
  >(null);

  const [footerPaymentMethodsLoading, setFooterPaymentMethodsLoading] =
    useState<boolean>(true);
  const [footerPaymentMethods, setFooterPaymentMethods] = useState<
    {
      icon: string;
      id: number;
      displayName: string;
      priority: number;
      methodName: string;
    }[]
  >([]);

  // const [showCookieStrip, setShowCookieStrip] = useState<boolean>(false);
  // const handleCookieStrip = useCallback(() => {
  //   // const isCookieAccepted = cookies.get('biscuit');
  //   // console.log(isCookieAccepted);
  //   cookies.set('biscuits', '1', {
  //     maxAge: 1000 * 60 * 60 * 24 * 365,
  //     domain: getCookieDomai n(),
  //     secure: true,
  //     path: '/',
  //   });
  //   saveEventV3({
  //     category: 'cookie',
  //     action: 'click',
  //     label: 'cookie',
  //     properties: '',
  //     value: ['1'],
  //     from: router,
  //   });
  //   setShowCookieStrip(false);
  // }, [router]);

  // useEffect(() => {
  //   if (router.pathname.includes('/user/')) {
  //     setShowCookieStrip(false);
  //   } else {
  //     setTimeout(() => {
  //       let cookieConsent = cookies.get('biscuits');
  //       if (cookieConsent) {
  //         setShowCookieStrip(false);
  //       } else {
  //         if (router.pathname.includes('/user/')) {
  //           setShowCookieStrip(false);
  //         } else {
  //           setShowCookieStrip(true);
  //         }
  //       }
  //     }, 5000);
  //   }
  // }, [router.pathname]);

  useEffect(() => {
    const oosProducts = cart.cartItems?.filter(
      (item) =>
        (item.availability !== null && item.availability < item.qty) ||
        item.unreservedQty > 0
    );

    const handleReviewCartOOS = () => {
      if (oosProducts && oosProducts.length > 0) {
        setNotAvailableProducts(oosProducts);
        dispatch(openShowReviewCartOOS());
      } else {
        dispatch(closeShowReviewCartOOS());
      }
    };

    if (router.pathname === '/cart' || router.pathname === '/checkout') {
      handleReviewCartOOS();
    }
  }, [cart.cartItems, router.pathname, dispatch]);

  /**
   * On first render fetch user and then fetch the user's cart
   */
  useEffect(() => {
    dispatch(fetchUser()).finally(() => {
      dispatch(fetchCarts(isESim)).catch(() => {});
      setFirstRender(false);
      setCleverTap(true);
    });
  }, []);

  useEffect(() => {
    if (user && cleverTap) {
      WE_USER_EVENT_TRACK_USER(user, cleverTap);
    }
  }, [user, cleverTap]);

  //todo: check if this is needed
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (
      typeof window !== 'undefined' &&
      router.query.currency &&
      typeof router.query.currency === 'string' &&
      router.query.currency !== ''
    ) {
      if (cookies.get('currency') !== router.query.currency) {
        let countryCode: string | null = null;
        if (typeof router?.query?.country === 'string') {
          countryCode = router.query.country;
        } else {
          countryCode = cookies.get('user-ip-location')?.country;
        }
        // else if(cookies.get('selected_country'))
        if (countryCode) {
          dispatch(setCurrency(router.query.currency, countryCode));
        }
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const cookieLanguage = cookies.get('language_bck');

    // console.log(locale, cookieLanguage);
    if (cookieLanguage !== locale) {
      axios
        .get(apiConstants.server + '/public/language?lang=' + locale, {
          withCredentials: true,
        })
        .then((res) => {
          // console.log(res.data);
        })
        .catch((err) => {
          // console.log(err.response.data);
        });
    }
  }, [router.isReady]);

  /**
   * language change modal
   */
  useEffect(() => {
    if (global.locationDetailsFetched) {
      let useLocale: LocalesType = getLocaleToBeUsed(locale);
      const localeForCountry: LocalesType = getLocaleForCountry(
        global?.locationDetails?.country_code
      );

      const hasUserPreviouslyDenied =
        JSON.parse(getLocalStorageExp('changeLanguage')) === '0';

      if (useLocale !== localeForCountry && !hasUserPreviouslyDenied) {
        setTimeout(() => {
          setLanguageSelectionModal(true);
        }, 2000);
      }
    }
  }, [
    JSON.stringify(global.locationDetails),
    JSON.stringify(global.locationDetailsFetched),
  ]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && router.isReady) {
  //     let countryData = cookies.get('selected_country');
  //     if (countryData) {
  //       dispatch(updateCountry(countryData));
  //     } else {
  //       const country = cookies.get('user-ip-location')?.country;
  //       if (country) {
  //         dispatch(updateCountry(country));
  //       }
  //     }
  //   }
  // }, [dispatch, router.isReady]);

  /**
   * Later when the user logs in or logs out fetch cart
   */
  useDidMountEffect(() => {
    if (firstRender) {
      return;
    }
    dispatch(fetchCarts(isESim)).catch(() => {});
  }, [user]);

  /**
   * Check the login query in route to open the login modal
   */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    setTimeout(() => {
      if (router.query.login === '1' && !isLoggedIn) {
        dispatch(openLoginModalRedux());
      }
    }, 500);

    if (router.query.source === 'dealstore') {
      setDealStore(true);
    }
  }, [router.query]);

  useEffect(() => {
    if (user) {
      const getRedirectionStatus = getLocalStorageExp('redirection');
      if (!getRedirectionStatus && router.query.redirectionUrl) {
        // console.log(1);
        router.push(decodeURIComponent(router.query.redirectionUrl as string));
        saveLocalStorageExp('redirection', '1', 1);
      }
    }
  }, [router, user]);

  const closeRegisterModal = useCallback(() => {
    dispatch(closeRegisterModalRedux());
  }, [dispatch]);

  const closeLoginModal = useCallback(() => {
    if (router.query.login) {
      router.query.login = '0';
      router.push(router);
    }
    dispatch(
      set2FARequired({
        is2FARequired: false,
        user: null,
      })
    );
    dispatch(closeLoginModalRedux());
  }, [dispatch, router]);

  const closeValidateEmailForm = useCallback(() => {
    dispatch(closeValidateEmailFormRedux());
  }, [dispatch]);

  const closeValidateEmailMessage = useCallback(() => {
    dispatch(closeValidateEmailMessageRedux());
  }, [dispatch]);

  const closeForgotPasswordForm = useCallback(() => {
    dispatch(closeForgotPasswordFormRedux());
  }, [dispatch]);

  const closeForgotPasswordMessage = useCallback(() => {
    dispatch(closeForgotPasswordMessageRedux());
  }, [dispatch]);

  const swapInLoginModal = useCallback(() => {
    dispatch(swapInLoginModalRedux());
  }, [dispatch]);

  const swapInRegisterModal = useCallback(() => {
    dispatch(swapInRegisterModalRedux());
  }, [dispatch]);

  const toggleMembershipHook = useToggleMembership();

  useEffect(() => {
    if (cart && cart.cartItems.length === 0 && toggleMembershipHook.status) {
      console.log('toggle membership false');
      toggleMembershipHook.toggleMembership(false);
    }
  }, [cart, toggleMembershipHook]);

  // const cjPixelPageViewAction = useCallback(() => {
  //   if (cart.isFetching === 'fetched' && router.isReady) {
  //     const itemsInCart = cart.cartItems.map((item) => {
  //       return {
  //         unitPrice: item.price,
  //         itemId: item.product.productId,
  //         quantity: item.qty,
  //         discount: 0,
  //       };
  //     });

  //     var script1 = document.createElement('script');
  //     script1.type = 'text/javascript';
  //     script1.innerHTML = `
  //   if (!window.cj) window.cj = {};
  //   cj.sitePage = {
  //       enterpriseId: ${1566899},
  //       pageType: ${JSON.stringify(getPageData(router).pageName)},
  //       userId: "${SHA256(user?.email ?? '').toString()}",
  //       emailHash: "${SHA256(user?.email ?? '').toString()}",
  //       cartSubtotal: "${cart.baseSubTotal}",
  //       items: ${JSON.stringify(itemsInCart)}
  //   };
  //     `;
  //     document.body.append(script1);
  //     // Additional script for loading the tag.js
  //     var cjScript = document.createElement('script');
  //     cjScript.type = 'text/javascript';
  //     cjScript.src = 'https://www.mczbf.com/tags/598241255716/tag.js';
  //     cjScript.async = true;
  //     cjScript.id = 'cjapitag';
  //     document.body.append(cjScript);
  //   }
  // }, [cart.isFetching, router, user?.email]);

  // useEffect(() => {
  //   cjPixelPageViewAction();
  // }, [cjPixelPageViewAction]);

  /**
   * Fetch the country list and the regions list
   */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    dispatch(fetchPlatformList());
    dispatch(fetchCountryList());
    dispatch(fetchRegionsList());
  }, [router.query]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const userLocation = cookies.get('user-ip-location');
  //     if (userLocation && userLocation?.country) {
  //       if (
  //         global.locationDetails.country_name ||
  //         global.locationDetails.country_code
  //       ) {
  //         clearInterval(interval);
  //         return;
  //       }
  //       if (getLocalStorageExp('countryArray')) {
  //         dispatch(fetchGeoLocation());
  //       }
  //     }
  //   }, 200);
  //   return () => clearInterval(interval);
  // }, [JSON.stringify(global.locationDetails)]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (typeof Storage === 'undefined') {
      return;
    }

    // //@ts-ignore
    // if (typeof window?.nSureSDK?.getDeviceId !== 'function') {
    //   return;
    // }

    try {
      //@ts-ignore
      const nsureDeviceId = window?.nSureSDK?.getDeviceId();
      if (typeof nsureDeviceId === 'string') {
        setNsureCookie(nsureDeviceId);
      }
    } catch (err) {
      // console.log(err);
    }
  });

  const appTheme = useTheme();

  useEffect(() => {
    const handleGoogleSignIn = () => {
      (window as any)?.google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
        scope: [
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/userinfo.email',
        ],
        callback: handleCredentialResponse,
      });
      (window as any)?.google.accounts.id.prompt();
    };

    const handleCredentialResponse = (response: any) => {
      WE_USER_EVENT_SOCIAL_LOGIN_INITIATE('google-one-tap');
      dispatch(googleOneTapLogin(response.credential, router, cleverTap));
      // Here you can handle the response from Google Sign-In API
      // e.g., send the user's ID token to your server for authentication
    };

    const loadGoogleSignInAPI = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.defer;
      script.onload = () => {
        handleGoogleSignIn();
      };
      document.body.appendChild(script);
    };

    if (user === null && process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID) {
      loadGoogleSignInAPI();
    }
  }, [dispatch, user, cleverTap]);

  useEffect(() => {
    const trustpilotWidgetStatus = cookies.get('trustpilot-widget') !== '1';
    if (trustpilotWidgetStatus) {
      const t = setTimeout(() => {
        dispatch(openTrustpilotWidget());
      }, 2000);

      return () => clearTimeout(t);
    } else {
      dispatch(closeTrustpilotWidget());
    }
  }, []);

  useEffect(() => {
    if (isESim) {
      return;
    }
    const countryCode = global.locationDetails?.country_code;
    const currency = global.locationDetails?.currency;
    const language = router.locale;
    const region = global.locationDetails?.country_name;
    const device_uid = cookies.get('device_uid');

    if (
      !firstRender &&
      // getLocalStorage('rru') !== 1 &&
      countryCode &&
      currency &&
      language &&
      region
    ) {
      const intervalId = setInterval(() => {
        if (device_uid) {
          sendRecombeeUserSetEvent({
            country: countryCode,
            currency: currency,
            language: language,
            region: region,
            subscription: userData?.subscription,
          });
          clearInterval(intervalId);
        }
      }, 200);

      return () => clearInterval(intervalId);
    }
  }, [
    router.locale,
    firstRender,
    global.locationDetails,
    isESim,
    userData?.subscription,
  ]);

  useEffect(() => {
    setFooterPaymentMethodsLoading(true);
    axios
      .get(
        apiConstants.server +
          '/public/footer/payment-strip?countryCode=' +
          global.geoLocation?.country_code
      )
      .then(({ data }) => {
        setFooterPaymentMethods(data.data);
        setFooterPaymentMethodsLoading(false);
      })
      .catch((e) => {
        setFooterPaymentMethodsLoading(false);
      });
  }, [global.geoLocation?.country_code]);

  return (
    <>
      {!isESim && WEBENGAGE_LICENCE_CODE && (
        <Script
          id="_webengage_script_tag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            try{
              var webengage; !function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i<l.length;i++)o(r,[l[i]]);for(i=0;i<a.length;i++){for(r[a[i]]={},s=0;s<c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s<p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i<u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://ssl.widgets.webengage.com":"http://cdn.widgets.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");
              webengage.init('${WEBENGAGE_LICENCE_CODE}');
            }catch(err){
              console.log(err)
            }
          `,
          }}
        />
      )}
      <div style={{ background: appTheme.palette.background.bg2 }}>
        {(!isLoggedIn && userRegister) ||
        (userLogin && !isLoggedIn) ||
        (validateEmailForm && !isLoggedIn) ||
        (validateEmailMessage && !isLoggedIn) ||
        (forgotPasswordForm && !isLoggedIn) ||
        (forgotPasswordMessage && !isLoggedIn) ? (
          <MUIModal
            theme={appTheme}
            open={
              userRegister
                ? userRegister
                : userLogin
                ? userLogin
                : validateEmailForm
                ? validateEmailForm
                : validateEmailMessage
                ? validateEmailMessage
                : forgotPasswordForm
                ? forgotPasswordForm
                : forgotPasswordMessage
            }
            handleClose={
              userRegister
                ? closeRegisterModal
                : userLogin
                ? closeLoginModal
                : validateEmailForm
                ? closeValidateEmailForm
                : validateEmailMessage
                ? closeValidateEmailMessage
                : forgotPasswordForm
                ? closeForgotPasswordForm
                : closeForgotPasswordMessage
            }
            title={
              userRegister
                ? "Let's get started"
                : userLogin
                ? 'Welcome back'
                : validateEmailForm || validateEmailMessage
                ? 'Almost there'
                : 'Reset password'
            }
          >
            {userRegister ? (
              // <NewSignupForm swapInLoginModal={swapInLoginModal} />
              <RegisterFormG swapInLoginModal={swapInLoginModal} />
            ) : userLogin ? (
              // <NewLoginForm swapInRegisterModal={swapInRegisterModal} />
              <LoginFormG swapInRegisterModal={swapInRegisterModal} />
            ) : validateEmailForm ? (
              <ValidateEmailForm />
            ) : validateEmailMessage ? (
              <ValidateEmailMessage />
            ) : forgotPasswordForm ? (
              <ForgotPasswordForm />
            ) : (
              <ForgotPasswordMessage />
            )}
          </MUIModal>
        ) : null}

        <FooterPaymentMethodsContext.Provider
          value={{
            footerPaymentMethods,
            footerPaymentMethodsLoading,
          }}
        >
          <main>
            <ErrorBoundary
              FallbackComponent={ErrorFallbackComponent}
              // onError={(error, errorInfo) => errorService.log({ error, errorInfo })}
              onReset={() => {
                // reset the state of your app
              }}
            >
              <NoJSMessage />
              {!isESim && <GlobalSiteBanner />}
            </ErrorBoundary>
            {children}
          </main>
        </FooterPaymentMethodsContext.Provider>

        {snackbarState.state && (
          <CustomizedSnackbars
            message={snackbarState.message}
            open={snackbarState.state}
            setOpen={(x: boolean) =>
              dispatchSnackbar({ type: 'close_snackbar', payload: '' })
            }
            severity={snackbarState.type}
          />
        )}
        {dealStore && (
          <DealStorePopup
            open={dealStore}
            handleClose={() => setDealStore(false)}
          />
        )}
        {rlcSelector && (
          <RLCSelector
            open={rlcSelector}
            handleClose={() => {
              dispatch(closeRlcSelectorRedux());
            }}
          />
        )}
        {!isESim && langauageSelectionModal && (
          <ChangeLanguageSuggestion
            open={langauageSelectionModal}
            handleClose={() => {
              setLanguageSelectionModal(false);
              saveLocalStorageExp('changeLanguage', '0', 1440);
            }}
          />
        )}
        {mobileSearchDialog && (
          <MobileSearchDialog
            open={mobileSearchDialog}
            handleClose={() => {
              dispatch(closeMobileSearchDialog());
            }}
          />
        )}
        {router.pathname === '/[slug]' ||
        router.pathname.includes('/store') ||
        router.pathname.includes('/sale') ||
        router.pathname.includes('/vendor') ||
        router.pathname === '/' ? (
          <TrustpilotWidget
            displayStatus={showTrustpilotWidget}
            handleClose={() => {
              dispatch(closeTrustpilotWidget());
            }}
          />
        ) : null}

        {showReviewCartOOS &&
          notAvailableProducts &&
          notAvailableProducts?.length > 0 && (
            <ReviewYourCart
              open={showReviewCartOOS}
              notAvailableProducts={notAvailableProducts}
            />
          )}

        {showCartMismatchDialog && (
          <CartMismatchNotice
            open={true}
            handleClose={() => dispatch(closeCartMismatchDialog())}
          />
        )}

        {/* {showCookieStrip && (
        <CookieStrip open={showCookieStrip} handleClose={handleCookieStrip} />
      )} */}
        {pageLoaderState.state ? <PageLoader /> : null}

        {subscriptionLimitDialog && (
          <SubscriptionLimitSnackbar
            open={subscriptionLimitDialog}
            handleClose={() => {
              dispatch(closeSubscriptionLimitDialog());
            }}
          />
        )}
      </div>
    </>
  );
};

export default Layout;
