import axios from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { apiConstants } from '../../../constants/apiConstants';
import { PageLoaderContext } from '../../../contexts/PageLoaderContext';
import { fetchCarts } from '../../../redux/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

function useToggleMembership() {
  const { subscription } = useAppSelector((state) => state.cart);
  const [status, setStatus] = useState<boolean>(subscription);
  const { dispatchPageLoader } = useContext(PageLoaderContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setStatus((prev) => (prev !== subscription ? subscription : prev));
  }, [subscription]);

  const toggleMembership = useCallback(
    async (status: boolean) => {

      try {
        dispatchPageLoader({ type: 'show' });
        const payload: {
          haveSubscriptionInCart: boolean;
        } = {
          haveSubscriptionInCart: status,
        };

        await axios.post(
          apiConstants.server + '/public/v2/cart/subscription',
          payload,
          {
            withCredentials: true,
          }
        );
        setStatus(status);
        await dispatch(fetchCarts());
        return true;
      } catch (error) {
        return false;
      } finally {
        dispatchPageLoader({ type: 'hide' });
      }
    },
    [dispatch, dispatchPageLoader]
  );

  const memoisedValues = useMemo(() => {
    return { toggleMembership, status };
  }, [toggleMembership, status]);

  return memoisedValues;
}

export default useToggleMembership;
