import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { NextRouter } from 'next/router';
import { Dispatch } from 'redux';
import { apiConstants } from '../constants/apiConstants';
import { mergeFacetsDistribution } from '../helper/helper';
import { FilterResponse, QueryFilters } from '../Interface/FiltersInterface';
import { ProductCardInterface } from '../Interface/ProductCardInterface';
import { StoreStats } from '../Interface/StoreStatsInterface';
import { saveEventV3 } from '../utils/eventTracking';

// Define a type for the slice state
interface ListerState {
  filters: FilterResponse | null;
  selectedQueryFilters: QueryFilters;
  products: Array<ProductCardInterface> | null;
  infiniteProducts: Array<ProductCardInterface> | null;
  currentPage: number;
  totalNumberOfProducts: number;
  loading: boolean;
  storeStats: StoreStats | null;
  productSearch: string | null | undefined;
  infiniteLoading: boolean;
}

// Define the initial state using that type
const initialState: ListerState = {
  filters: null,
  selectedQueryFilters: {
    genre: [],
    lang: [],
    platform: [],
    productType: [],
    region: [],
    worksOn: [],
  },
  products: null,
  infiniteProducts: null,
  currentPage: 1,
  totalNumberOfProducts: 0,
  loading: true,
  infiniteLoading: false,
  storeStats: null,
  productSearch: null,
};

// export const fetchFilters = () => async (dispatch: Dispatch) => {
//   await axios
//     .get(apiConstants.server + '/public/filters', { withCredentials: true })
//     .then((response) => {
//       dispatch(setFilters(response.data.facetsDistribution));
//     })
//     .catch((error) => {
//       dispatch(setFilters(null));
//     });
// };

export const storeProductSearch =
  (searchQuery: string, router: NextRouter) => async (dispatch: Dispatch) => {
    dispatch(setSearchProducts(searchQuery));
    dispatch(setCurrentPage(1));
    dispatch(setInfiniteProducts({ data: [], clearPrevious: true }));
    if (searchQuery.length > 0) {
      saveEventV3({
        category: 'filters',
        action: 'input_text',
        label: 'search',
        properties: '',
        value: [searchQuery],
        from: router,
      });
    }
  };

export const fetchProducts =
  (
    x: any,
    storeId: string | string[] | null | undefined,
    oldFacets: any,
    router: NextRouter,
    productSearch?: string | null | undefined
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    let uri = apiConstants.search + `/products/v2/list?limit=50`;

    if (storeId && storeId?.length > 0) {
      uri += `&store=${storeId}`;
    }

    let updatedQuery = { ...x };

    if (productSearch && productSearch?.length > 0) {
      uri += '&q=' + productSearch;
      updatedQuery = { ...x, q: undefined };
    }

    for (const key in updatedQuery) {
      if (Array.isArray(updatedQuery[key])) {
        updatedQuery[key] = updatedQuery[key].toString();
      }
    }

    await axios
      // ${storeId.length>0 ? `/store/${storeId}` : 'catalog/v2/products'}
      .get(uri, {
        withCredentials: true,
        params: updatedQuery,
      })
      .then((response) => {
        // if (storeId && storeId?.length > 0) {
        //   dispatch(setProducts(response.data.products));
        // } else {
        dispatch(setProducts(response.data.data ?? []));
        let a = oldFacets;
        let b = response.data.facetsDistribution;
        let updatedFacets = mergeFacetsDistribution(a, b);
        dispatch(setFilters(updatedFacets));

        // if (storeId) {
        //   if (Number.isInteger(response.data.totalProducts)) {
        //     dispatch(setTotalNumberOfProducts(response.data.totalProducts));
        //   }
        // } else {
        if (Number.isInteger(response.data.totalNumberOfProducts)) {
          dispatch(
            setTotalNumberOfProducts(response.data.totalNumberOfProducts)
          );
        }
        if (productSearch && response.data.data.length === 0) {
          saveEventV3({
            action: 'input_text',
            category: 'filter',
            label: 'search',
            properties: 'no-results',
            value: [productSearch],
            from: router,
          });
        }
        // }
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setProducts(null));
        dispatch(setInfiniteProducts({ data: null, clearPrevious: false }));
        dispatch(setLoading(false));
      });
  };

export const fetchStoreStats =
  (storeId: string | string[] | null | undefined) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(apiConstants.server + `/public/v2/store/vals/${storeId}`, {
        withCredentials: true,
      })
      .then((response) => {
        dispatch(setStoreStats(response.data.data));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setStoreStats(null));
        dispatch(setLoading(false));
      });
  };

export const fetchProductsInfinite =
  (
    x: any,
    currentPage: number,
    storeId: string | string[] | null | undefined,
    oldFacets: any,
    router: NextRouter,
    productSearch?: string | null | undefined
  ) =>
  async (dispatch: Dispatch) => {
    if (currentPage === 1) {
      dispatch(setLoading(true));
    } else {
      dispatch(setInfiniteLoading(true));
    }
    let uri = apiConstants.search + `/products/v2/list?limit=24`;

    if (storeId && storeId?.length > 0) {
      uri += `&store=${storeId}`;
    }

    let updatedQuery = { ...x };

    if (productSearch && productSearch?.length > 0) {
      uri += '&q=' + productSearch;
      let { q, ...rest } = updatedQuery;
      updatedQuery = rest;
    }

    // if (currentPage) {
    //   uri += `&page=${currentPage}`;
    // }

    for (const key in updatedQuery) {
      if (Array.isArray(updatedQuery[key])) {
        updatedQuery[key] = updatedQuery[key].toString();
      }
    }

    await axios
      .get(uri, {
        withCredentials: true,
        params: {
          ...updatedQuery,
          page: currentPage ?? undefined,
        },
      })
      .then((response) => {
        dispatch(
          setInfiniteProducts({
            data: response.data.data ?? [],
            clearPrevious: currentPage === 1,
          })
        );

        let a = oldFacets;
        let b = response.data.facetsDistribution;
        let updatedFacets = mergeFacetsDistribution(a, b);
        // console.log('updated', updatedFacets);
        dispatch(setFilters(updatedFacets));

        if (Number.isInteger(response.data.totalNumberOfProducts)) {
          dispatch(
            setTotalNumberOfProducts(response.data.totalNumberOfProducts)
          );
        }

        if (currentPage === 1) {
          dispatch(setLoading(false));
        } else {
          dispatch(setInfiniteLoading(false));
        }

        if (productSearch && response.data.data.length === 0) {
          saveEventV3({
            action: 'input_text',
            category: 'filter',
            label: 'search',
            properties: 'no-results',
            value: [productSearch],
            from: router,
          });
        }
      })
      .catch((error) => {
        dispatch(setProducts(null));
        dispatch(setInfiniteProducts({ data: null, clearPrevious: false }));
        if (currentPage === 1) {
          dispatch(setLoading(false));
        } else {
          dispatch(setInfiniteLoading(false));
        }
      });
  };

export const listerSlice = createSlice({
  name: 'lister',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setInfiniteLoading: (state, action) => {
      state.infiniteLoading = action.payload;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    // setPAge
    setSelectedQueryFilters: (state, action) => {
      state.selectedQueryFilters = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setInfiniteProducts: (state, action) => {
      state.infiniteProducts = [
        ...(action.payload.clearPrevious
          ? []
          : current(state)?.infiniteProducts ?? []),
        ...(action.payload.data ?? []),
      ];
    },
    setProductsEmpty: (state, action) => {
      state.infiniteProducts = null;
      state.products = null;
    },
    setTotalNumberOfProducts: (state, action) => {
      state.totalNumberOfProducts = action.payload;
    },
    setInitialState: (state) => {
      state.filters = null;
      state.selectedQueryFilters = {
        genre: [],
        lang: [],
        platform: [],
        productType: [],
        region: [],
        worksOn: [],
      };
      state.products = null;
      state.infiniteProducts = null;
      state.currentPage = 1;
      state.totalNumberOfProducts = 0;
      state.loading = true;
      state.infiniteLoading = false;
      state.storeStats = null;
      state.productSearch = null;
    },
    setStoreStats: (state, action) => {
      state.storeStats = action.payload;
    },
    setSearchProducts: (state, action) => {
      state.productSearch = action.payload;
    },
    // setCurrentPageZero: (state) => {
    //   state.currentPage = 0;
    // },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFilters,
  setSelectedQueryFilters,
  setProducts,
  setInfiniteProducts,
  setTotalNumberOfProducts,
  setInitialState,
  setProductsEmpty,
  setLoading,
  setInfiniteLoading,
  setStoreStats,
  setSearchProducts,
  setCurrentPage,
} = listerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.count;

export default listerSlice.reducer;
